import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class NetworkService {

  constructor(private platform: Platform) {
  }

  get isOnline(): boolean {
    let resultado: boolean = false;

    // if (this.platform.is('cordova')) {
    //   if (navigator.connection && navigator.connection.type) {
    //     resultado = (navigator.connection.type != Connection.UNKNOW && navigator.connection.type != Connection.NONE);
    //   }
    //   else {
    //     resultado = true;
    //   }
    // }
    // else {
    //   resultado = navigator.onLine;
    // }

    return resultado;

    return true;
  }
}
