export class RetornoModel{
    // constructor(){
    //     this.Sucesso = false;
    //     this.Objeto = null;
    //     this.Mensagem = '';
    //     this.Erro = null;
    // }

    Sucesso: boolean;
    Mensagem: string;
    Erro: any;
    Objeto: any;
}
