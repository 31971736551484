//import { enLocalEntrega } from './carrinhoModel';

export class UsuarioModel {
    constructor() {
        this.Sucesso = false;
    }

    public Sucesso: boolean;
    public Mensagem: string;

    public Codigo: number;
    public Login: string;
    public Nome: string;

    public Autenticado: boolean;
    public EstaCadastrado: boolean;

    public Cep: string;
    public Endereco: string;
    public Bairro: string;
    public Cidade: string;
    public NumeroResidencia: string;
    public Complemento: string;
    public Telefone: string;

    public listaNegra: boolean;
    public CodigoSmsAutorizacao: string;
}
