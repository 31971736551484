import { BairrosEntrega } from "./localEntregaModel";

export class EmpresaModel {
    constructor() {
        this.Sucesso = false;
    }

    public Sucesso: boolean;
    public Mensagem: string;

    public Codigo: number;
    public Nome: string;
    public Endereco: string;
    public Bairro: string;
    public Cidade: string;
    public Telefone: string;
    public Whatsapp: string;
    public Mensagem1: string;
    public Mensagem2: string;

    public PermitePedidoSemLogin: boolean;
    public PermiteRetiradaNaLoja: boolean;
    public PermiteEntregaEmCasa: boolean;
    public PermiteItemEsgotado: boolean;
    public ExibeGrupoProdutos: boolean;
    public ValorMinimoPedido: number;

    public RedesSociaisEmail: string;
    public RedesSociaisFacebook: string;
    public RedesSociaisInstagram: string;
    public RedesSociaisSite: string;
    public RedesSociaisTelefone: string;
    public RedesSociaisWhatsapp: string;

    public PathImagem: string;
    public PathImagemFundo: string;
    public TipoValidacaoEntrega: number;
    // public TempoMedioAtendimento: string;
    // public HorarioAtendimento: string;
    // public TaxaEntrega: number;
    // public EmFuncionamento: boolean;

    //Mantenho carregado antes para não precisar ficar recarregando
    public ListaTaxaEntrega: BairrosEntrega[];
}
