import { WeekDay } from '@angular/common';

export class HorarioFuncionamentoModel {
    constructor() {
        this.Sucesso = false;
    }
    
    public Sucesso: boolean;
    public Mensagem: string;

    public Relacao: DetalheHorarioFuncionamentoModel[];
}
export class DetalheHorarioFuncionamentoModel {
    public DiaDaSemana: string;
    public HoraFuncionamento: string;
}
