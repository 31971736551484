import { Injectable } from '@angular/core';
import { AlertController, ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(
    private alertController: AlertController,
    private toastController: ToastController) {
  }

  public async Mensagem(titulo: string, tempoEmSegundos:number): Promise<void> {

    if (tempoEmSegundos == 0){
      tempoEmSegundos = 2; 
    }

    // tslint:disable-next-line:prefer-const
    let toast = await this.toastController.create({
      message: titulo,
      duration: (tempoEmSegundos * 1000)
    });

    toast.present();
  }

  public async MensagemPopUp(mensagem: string): Promise<void> {

    let toast = await this.toastController.create({
      header: mensagem,
      duration: 750
    });

    await toast.present();
  }

  public async Alerta(titulo: string, mensagem: string, permiteClicarFora: boolean): Promise<void> {
    let alerta = await this.alertController.create({
      header: titulo,
      message: mensagem,
      buttons: ['Ok'],
      backdropDismiss: permiteClicarFora
    });

    alerta.present();
  }

  async Confirma(titulo: string, mensagem: string, permiteClicarFora: boolean, callbackSim: any, callbackNao: any): Promise<void> {
    if (!callbackNao) {
      callbackNao = () => { };
    }

    if (!callbackSim) {
      callbackSim = () => { };
    }

    let confirmacao = await this.alertController.create({
      header: titulo,
      message: mensagem,
      buttons: [
        {
          text: 'Sim',
          handler: () => {
            callbackSim();
          }
        },
        {
          text: 'Não',
          handler: () => {
            callbackNao();
          }
        }
      ],
      backdropDismiss: permiteClicarFora
    });

    await confirmacao.present();
  }
}
