import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';
import { AlertService } from '../alert/alert.service';
import { UsuarioModel } from '../../models/usuarioModel';
import { Global } from '../../helpers/global';
import { EmpresaModel } from 'src/app/models/empresaModel';
import { HorarioFuncionamentoModel } from 'src/app/models/horarioFuncionamentoModel';
import { GrupoModel, DetalheGrupoModel } from 'src/app/models/grupoModel';
import { ProdutoModel, DetalheProdutoModel, DetalheProdutoGrupoAcompanhamentoModel } from 'src/app/models/produtoModel';
import { Plugins } from '@capacitor/core';
import { FormaPagamentoModel, DetalheFormaPagamentoModel } from 'src/app/models/FormaPagamentoModel';
import { RetornoModel } from 'src/app/models/retornoModel';
import { CarrinhoModel } from 'src/app/models/carrinhoModel';
import { DetalhePedidoModel, PedidoModel } from 'src/app/models/pedidoModel';
import { FuncionamentoModel } from 'src/app/models/FuncionamentoModel';
import { LocalEntregaModel } from 'src/app/models/localEntregaModel';
import { SpinnerService } from '../spinner/spinner.service';
import { AcompanhamentoModel, DetalheAcompanhamentoModel, DetalheProdutoAcompanhamentoModel } from 'src/app/models/acompanhamentoModel';
import { IdentificacaoModel } from 'src/app/models/identificacaoModel';
import { enLocalEntrega } from 'src/app/models/enums/enLocalEntrega';
import { enTipoAcompanhamento } from 'src/app/models/enums/enTipoAcompanhamento';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

@Injectable({
  providedIn: 'root'
})
export class DeliveryService {
  //_storage = Plugins;

  //_localEntrega: LocalEntregaModel;
  _usuario: UsuarioModel;
  _empresa: EmpresaModel;
  _grupoProduto: GrupoModel;
  _produto: ProdutoModel;
  _imagensProduto: DetalheProdutoModel[];
  _formaPagamento: FormaPagamentoModel;
  _funcionamento: FuncionamentoModel;
  _observacaoPedido: string;
  _acompanhamento: AcompanhamentoModel;
  _horarioFuncionamento: HorarioFuncionamentoModel;

  constructor(private httpSrv: HttpService,
    //private storage: Storage,
    private alertaSrv: AlertService,
    private spinnerSrv: SpinnerService,
    private browserSrv: InAppBrowser) { }

  public async ResetarInformacaoCache() {
    Global.Usuario = null;
    Global.Empresa = null;
    Global.GrupoProduto = null;
    Global.Produto = null;
    Global.FormaPagamento = null;
    Global.Funcionamento = null;
    Global.ObservacaoPedido = null;
    Global.Acompanhamento = null;
    // this.GravarConteudoStorage(Global.ChaveUsuario, "");
    // this.GravarConteudoStorage(Global.ChaveEmpresa, "");
    // this.GravarConteudoStorage(Global.ChaveGrupo, "");
    // this.GravarConteudoStorage(Global.ChaveProduto, "");
    // this.GravarConteudoStorage(Global.ChaveFormaPagamento, "");
    // this.GravarConteudoStorage(Global.ChaveFuncionamento, "");
    // this.GravarConteudoStorage(Global.ChaveLocalEntrega, "");
    // this.GravarConteudoStorage(Global.ChaveObservacaoPedido, "");
  }

  public async AcaoSairDoSistema(): Promise<void> {
    let c = (() => {
      navigator['app'].exitApp();
    });

    await this.alertaSrv.Confirma("Sair do sistema", "Deseja realmente sair?", false, c, null);
  }

  // public async GravarConteudoUsuario(conteudo: string): Promise<void> {
  //   this.GravarConteudoStorage(Global.ChaveUsuario, conteudo);
  // }

  // private async GravarConteudoStorage(chave: string, conteudo: string): Promise<void> {
  //   await this._storage.Storage.set({
  //     key: chave,
  //     value: conteudo
  //   });
  // }

  // private async CarregarConteudoStorage(chave: string): Promise<string> {
  //   let c = async () => {
  //     let atributo: string = '';
  //     return new Promise(async (resolve) => {
  //       let c = (async () => {
  //         let v = await this._storage.Storage.get({ key: chave });
  //         if (v) {
  //           atributo = String(v.value);
  //         }
  //       });

  //       await c();
  //       resolve(atributo);
  //     });
  //   };

  //   let ret: string = '';
  //   await c().then(res => { ret = (res as string); }).catch(e => console.log(e));
  //   return ret;
  // }

  async CarregarinformacoesFuncionamentoSistema(): Promise<void> {
    let c = async () => {
      return new Promise<void>(async (resolve) => {
        //this.spinnerSrv.Show("Carregando informações do sistema...");

        // Global.LocalEntrega = new LocalEntregaModel();
        // Global.LocalEntrega.LocalEntrega = enLocalEntrega.Casa;
        // Global.LocalEntrega.Cep = "";
        // Global.LocalEntrega.Endereco = "";
        // Global.LocalEntrega.Bairro = "";
        // Global.LocalEntrega.Cidade = "";
        // Global.LocalEntrega.NumeroResidencia = "";
        // Global.LocalEntrega.Complemento = "";

        // this._localEntrega = new LocalEntregaModel();
        //this.GravarInformacoesLocalEntrega();
        let identificacao = await this.IdentificarEmpresa();
        if (identificacao) {
          Global.IdentificacaoEmpresa = identificacao.Id;
          Global.EhCardapioVirtual = identificacao.EhCardapioVirtual;
        }

        this._empresa = await this.httpSrv.CarregarInformacoesEmpresa();
        if (this._empresa) {
          //Se a empresa trabalha por bairro eu carrego os bairros de entrega
          if (this._empresa.TipoValidacaoEntrega == 2) {
            let c = async () => {
              return new Promise<void>(async (resolve) => {
                let _bairrosEntrega = await this.CarregarBairrosEntrega();

                //Carrego todos os Bairros
                if (_bairrosEntrega) {
                  if (_bairrosEntrega.ListaTaxaEntrega) {
                    if (_bairrosEntrega.ListaTaxaEntrega.length > 0) {
                      this._empresa.ListaTaxaEntrega = _bairrosEntrega.ListaTaxaEntrega;
                      resolve();
                    }
                  }
                }

              });
            };

            await c()
              .then(res => { })
              .catch(e => console.log(e));
          }

          Global.Empresa = this._empresa;
          // let e = JSON.stringify(this._empresa);
          // this.GravarConteudoStorage(Global.ChaveEmpresa, e);
        }

        this._funcionamento = await this.httpSrv.ObterInformacoesFuncionamentoEmpresa();
        if (this._funcionamento) {
          Global.Funcionamento = this._funcionamento;
          // let e = JSON.stringify(this._funcionamento);
          // this.GravarConteudoStorage(Global.ChaveFuncionamento, e);
        }

        this._grupoProduto = await this.httpSrv.CarregarInformacoesGrupoProduto();
        if (this._grupoProduto) {
          Global.GrupoProduto = this._grupoProduto;
          // let e = JSON.stringify(this._grupoProduto);
          // this.GravarConteudoStorage(Global.ChaveGrupo, e);
        }

        await this.CarregarInformacoesProdutos();
        await this.CarregarFotosProdutos();
        await this.CarregarInformacoesFormaPagamento();

        this._observacaoPedido = "";
        this.GravarinformacoesObservacaoPedido(this._observacaoPedido);
        Global.QuantidadeItensCarrinho = 0;

        if (!Global.EhCardapioVirtual) {
          this._acompanhamento = await this.httpSrv.CarregarInformacoesAcompanhamentoPedido();
          if (this._acompanhamento) {
            Global.Acompanhamento = this._acompanhamento;
            // let e = JSON.stringify(this._acompanhamento);
            // this.GravarConteudoStorage(Global.ChaveAcompanhamento, e);
          }
        }

        this._horarioFuncionamento = await this.httpSrv.CarregarInformacoesFuncionamentoEmpresa();
        if (this._horarioFuncionamento) {
          Global.ListaHorarioFuncionamento = this._horarioFuncionamento;
        }

        resolve();
      });
    };

    await c()
      .then(res => {
        //this.spinnerSrv.Hide();

        let s = "";

        // Verifico se carregou as informações de EMPRESA
        if (!this._empresa.Sucesso) {
          // s += (this._empresa.Mensagem)
          //   ? this._empresa.Mensagem + "\n"
          //   : "* Erro carregando informações de Empresas\n";
          s += (this._empresa.Mensagem)
            ? "* Erro carregando informações de Empresas<br/>"
            : "";
        }

        // Verifico se carregou as informações de HORÁRIO FUNCIONAMENTO
        if (!this._funcionamento.Sucesso) {
          // s += (this._funcionamento.Mensagem)
          //   ? this._funcionamento.Mensagem + "\n"
          //   : "* Erro carregando informações de Horário de Funcionamento\n";
          s += (this._funcionamento.Mensagem)
            ? "* Erro carregando informações de Horário de Funcionamento<br/>"
            : "";
        }

        // Verifico se carregou as informações de Grupo de Produtos
        if (!this._grupoProduto.Sucesso) {
          // s += (this._grupoProduto.Mensagem)
          //   ? this._grupoProduto.Mensagem + "\n"
          //   : "* Erro carregando informações de Grupo de Produtos\n";
          s += (this._grupoProduto.Mensagem)
            ? "* Erro carregando informações de Grupo de Produtos<br/>"
            : "";
        }

        // Verifico se carregou as informações de PRODUTO
        if (!this._produto.Sucesso) {
          // s += (this._produto.Mensagem)
          //   ? this._produto.Mensagem + "\n"
          //   : "* Erro carregando informações de Produtos\n";
          s += (this._produto.Mensagem)
            ? "* Erro carregando informações de Produtos<br/>"
            : "";
        }

        // Verifico se carregou as informações de FORMA DE PAGAMENTO
        if ((this._formaPagamento) && (!this._formaPagamento.Sucesso)) {
          // s += (this._formaPagamento.Mensagem)
          //   ? this._formaPagamento.Mensagem + "\n"
          //   : "* Erro carregando informações de Formas de Pagamento\n";
          s += (this._formaPagamento.Mensagem)
            ? "* Erro carregando informações de Formas de Pagamento\n"
            : "";
        }

        // Verifico se carregou as informações de Grupo de Produtos
        if ((this._acompanhamento) && (!this._acompanhamento.Sucesso)) {
          // s += (this._acompanhamento.Mensagem)
          //   ? this._acompanhamento.Mensagem + "\n"
          //   : "* Erro carregando informações de Acompanhamentos\n";
          s += (this._acompanhamento.Mensagem)
            ? "* Erro carregando informações de Acompanhamentos\n"
            : "";
        }

        if (!this._horarioFuncionamento.Sucesso) {
          s += (this._horarioFuncionamento.Mensagem)
            ? "* Erro carregando informações de Horário de Funcionamento\n"
            : "";
        }

        if (s) {
          // this.spinnerSrv.Hide();
          // this.spinnerSrv.Hide();
          // this.spinnerSrv.Hide();
          // this.spinnerSrv.Hide();
          // this.spinnerSrv.Hide();
          // this.spinnerSrv.Hide();
          // this.spinnerSrv.Hide();
          let mensagem = "Erro carregando informações<br/>" + s + "<br/><br/>Pressione F5"
          this.alertaSrv.Alerta("Erro carregando informações", mensagem, false);
        }
      })
      .catch(e => {
        //this.spinnerSrv.Hide();

        this.alertaSrv.Alerta("Erro", "Erro desconhecido. Pressione F5", false);
        console.log(e)
      })
      .finally(() => {
        //this.spinnerSrv.Hide();
      });
  }

  async CarregarInformacoesProdutos() {
    let c = async () => {
      return new Promise<void>(async (resolve) => {
        if (Global.PaginaAtualPesquisaProduto == 1) {
          this._produto = new ProdutoModel();
          this._produto.Relacao = [];
          this._produto.RelacaoCarrinho = [];
          this._imagensProduto = [];
        }

        let produto: ProdutoModel = await this.httpSrv.CarregarInformacoesProdutos(Global.PaginaAtualPesquisaProduto);

        if ((produto) &&
          (produto.Sucesso) &&
          (produto.Relacao)) {
          produto.Relacao.forEach(e => {
            if (this._produto.Relacao.findIndex(r => r.Codigo == e.Codigo) == -1) {
              this._produto.Relacao.push(e);
            }
          })

          Global.PaginaAtualPesquisaProduto++;

          await this.onGravarConteudoStorageProduto();
        }
        resolve();
      });
    };

    await c()
      .then(res => {
      })
      .catch(e => {
        console.log(e);
      });
  }

  // async CarregarInformacoesLocalEntrega(): Promise<void> {
  //   let c = async () => {
  //     return new Promise(async (resolve) => {

  //       let conteudoLocalEntrega = await this.CarregarConteudoStorage(Global.ChaveLocalEntrega);
  //       if (conteudoLocalEntrega) {
  //         this._localEntrega = JSON.parse(conteudoLocalEntrega);
  //         Global.LocalEntrega = this._localEntrega;
  //       }
  //       resolve();
  //     });
  //   };

  //   await c()
  //     .then(res => {
  //     })
  //     .catch(e => {
  //       console.log(e);
  //     });
  // }

  async CarregarInformacoesEmMemoria(): Promise<void> {
    let c = async () => {
      return new Promise<void>(async (resolve) => {

        //await this.CarregarInformacoesLocalEntrega();
        let identificacao = await this.IdentificarEmpresa();
        if (identificacao) {
          Global.IdentificacaoEmpresa = identificacao.Id;
        }

        await this.CarregarInformacoesUsuario();

        this._empresa = Global.Empresa;
        // let conteudoEmpresa = await this.CarregarConteudoStorage(Global.ChaveEmpresa);
        // if (conteudoEmpresa) {
        //   this._empresa = JSON.parse(conteudoEmpresa);
        // }

        this._grupoProduto = Global.GrupoProduto;
        // let conteudoGrupo = await this.CarregarConteudoStorage(Global.ChaveGrupo);
        // if (conteudoGrupo) {
        //   this._grupoProduto = JSON.parse(conteudoGrupo);
        // }

        await this.onCarregarConteudoStorageProduto();

        this._formaPagamento = Global.FormaPagamento;
        // let conteudoFormaPagamento = await this.CarregarConteudoStorage(Global.ChaveFormaPagamento);
        // if (conteudoFormaPagamento) {
        //   this._formaPagamento = JSON.parse(conteudoFormaPagamento);
        // }

        this._funcionamento = Global.Funcionamento;
        // let conteudoFuncionamento = await this.CarregarConteudoStorage(Global.ChaveFuncionamento);
        // if (conteudoFuncionamento) {
        //   this._funcionamento = JSON.parse(conteudoFuncionamento);
        // }

        this._observacaoPedido = Global.ObservacaoPedido;
        //this._observacaoPedido = await this.CarregarConteudoStorage(Global.ChaveObservacaoPedido);

        this._acompanhamento = Global.Acompanhamento;
        // let conteudoAcompanhamento = await this.CarregarConteudoStorage(Global.ChaveAcompanhamento);
        // if (conteudoAcompanhamento) {
        //   this._acompanhamento = JSON.parse(conteudoAcompanhamento);
        // }

        this._horarioFuncionamento = Global.ListaHorarioFuncionamento;

        await this.SelecionarProdutosComprados();

        resolve();
      });
    };

    await c()
      .then(res => {
      })
      .catch(e => {
        console.log(e);
      });
  }

  async RealizarLoginUsuario(login: string, senha: string, codigoSmsAutorizacao: string): Promise<UsuarioModel> {
    let c = async () => {
      return new Promise(async (resolve) => {

        let usuario: UsuarioModel = await this.httpSrv.RealizarLoginUsuario(login, senha, codigoSmsAutorizacao);

        if (!Global.EhProducao) {
          console.log(usuario);
        }

        if ((usuario.Sucesso) && (usuario.Autenticado)) {
          this._usuario = usuario;

          Global.Usuario = usuario;
          // let conteudo: string = JSON.stringify(this._usuario);
          // this.GravarConteudoStorage(Global.ChaveUsuario, conteudo);

          Global.LocalEntrega = new LocalEntregaModel();
          Global.LocalEntrega.LocalEntrega = enLocalEntrega.Loja;
          Global.LocalEntrega.Cep = "";
          Global.LocalEntrega.Endereco = "";
          Global.LocalEntrega.Bairro = "";
          Global.LocalEntrega.Cidade = "";
          Global.LocalEntrega.NumeroResidencia = "";
          Global.LocalEntrega.Complemento = "";
          Global.LocalEntrega.CepLocalizado = false;
          Global.LocalEntrega.TaxaEntrega = 0.00;

          if (this._usuario.Cep && this._empresa.TipoValidacaoEntrega == 0) {
            let retorno: RetornoModel = await this.ValidarDisponibilidadeEntregaNoCep(this._usuario.Cep, true, false);
            if (retorno.Sucesso) {
              Global.LocalEntrega.LocalEntrega = enLocalEntrega.Casa;
              Global.LocalEntrega.CepLocalizado = false;
              Global.LocalEntrega.Cep = this._usuario.Cep;
              Global.LocalEntrega.Endereco = this._usuario.Endereco;
              Global.LocalEntrega.Bairro = this._usuario.Bairro;
              Global.LocalEntrega.Cidade = this._usuario.Cidade;
              Global.LocalEntrega.NumeroResidencia = this._usuario.NumeroResidencia
              Global.LocalEntrega.Complemento = this._usuario.Complemento;
            }
          }
          else if (this._empresa.TipoValidacaoEntrega == 2) {

            Global.LocalEntrega.LocalEntrega = enLocalEntrega.Casa;
            Global.LocalEntrega.Cep = this._usuario.Cep;
            Global.LocalEntrega.Endereco = this._usuario.Endereco;
            Global.LocalEntrega.Cidade = this._usuario.Cidade;
            Global.LocalEntrega.NumeroResidencia = this._usuario.NumeroResidencia
            Global.LocalEntrega.Complemento = this._usuario.Complemento;

            if (this._empresa.ListaTaxaEntrega) {
              if (this._empresa.ListaTaxaEntrega.length > 0) {
                this._empresa.ListaTaxaEntrega.forEach(x => {
                  if (this._usuario.Bairro && x.Bairro == this._usuario.Bairro) {
                    Global.LocalEntrega.Bairro = this._usuario.Bairro;
                    Global.LocalEntrega.CepLocalizado = true;
                    Global.LocalEntrega.TaxaEntrega = x.ValorTaxaEntrega;
                  }
                });
              }
            }
          }

          // Global.LocalEntrega.LocalEntrega = this._localEntrega.LocalEntrega;
          // Global.LocalEntrega.Cep = this._localEntrega.Cep;
          // Global.LocalEntrega.Endereco = this._localEntrega.Endereco;
          // Global.LocalEntrega.Bairro = this._localEntrega.Bairro;
          // Global.LocalEntrega.Cidade = this._localEntrega.Cidade;
          // Global.LocalEntrega.NumeroResidencia = this._localEntrega.NumeroResidencia;
          // Global.LocalEntrega.Complemento = this._localEntrega.Complemento;

          //this.GravarInformacoesLocalEntrega();
        }

        resolve(usuario);
      });
    };

    let ret: UsuarioModel = new UsuarioModel();
    await c().then(res => { ret = (res as UsuarioModel); }).catch(e => console.log(e));
    return ret;
  }

  async RegistrarNovoUsuario(login: string,
    senha: string,
    confirmeSenha: string,
    nome: string,
    cep: string,
    endereco: string,
    bairro: string,
    cidade: string,
    numeroEndereco: string,
    complemento: string,
    telefone: string,
    validaCEP: boolean): Promise<RetornoModel> {
    let c = async () => {
      return new Promise(async (resolve) => {

        let retorno: RetornoModel = new RetornoModel();

        let validacao: string = "";
        if (!telefone) {
          validacao += "Número de telefone não informado.<br/>";
        }
        else if (telefone.length < 14) {
          validacao += (!telefone) ? "Número de telefone inválido.<br/>" : "";
        }
        validacao += (!senha) ? "Senha não informada.<br/>" : "";
        validacao += (senha != confirmeSenha) ? "Senhas não conferem.<br/>" : "";
        if (validaCEP)
          validacao += (!cep) ? "CEP  não informado.<br/>" : "";
        validacao += (!numeroEndereco) ? "Número do Endereço de Entrega não informado.<br/>" : "";

        if (validacao) {
          retorno.Sucesso = false;
          retorno.Mensagem = validacao;
        }
        else {
          retorno = await this.httpSrv.RegistrarNovoUsuario(login,
            senha,
            confirmeSenha,
            nome,
            cep,
            endereco,
            bairro,
            cidade,
            numeroEndereco,
            complemento,
            telefone);

          if (!Global.EhProducao) {
            console.log(retorno);
          }
        }

        resolve(retorno);
      });
    };

    let ret: RetornoModel = new RetornoModel();
    await c().then(res => { ret = (res as RetornoModel); }).catch(e => console.log(e));
    return ret;
  }

  SelecionarProdutosDeGrupo(codigoGrupo: number): DetalheProdutoModel[] {
    let relacao: DetalheProdutoModel[] = [];

    let c = async () => {
      return new Promise(async (resolve) => {
        if (this._produto.Relacao) {
          this._produto.Relacao.forEach(e => {
            if (e.CodigoGrupo === codigoGrupo) {
              relacao.push(e);
            }
            e.Quantidade = 1;
          });
        }

        resolve(relacao);
      });
    };

    c().then(res => { }).catch(e => { console.log(e); });
    return relacao;
  };

  AdicionarProdutoAoCarrinho(codigoProduto: number,
    quantidade: number,
    observacao: string,
    relacaoAcompanhamento: DetalheProdutoGrupoAcompanhamentoModel[],
    valorTotal: number) {

    let index = -1;
    for (let i = 0; i < this._produto.Relacao.length; i++) {
      let x = this._produto.Relacao.findIndex(y => y.Codigo == codigoProduto);

      if (x > -1) {
        index = x;
        break;
      }
    }

    if (index > -1) {
      let produto = this._produto.Relacao[index];
      produto.Quantidade = 1;
      produto.ObservacaoNoPedido = "";

      let produtoCarrinho: DetalheProdutoModel = new DetalheProdutoModel();
      produtoCarrinho.SequencialCarrinho = this._produto.RelacaoCarrinho.length;
      produtoCarrinho.Codigo = produto.Codigo;
      produtoCarrinho.CodigoGrupo = produto.CodigoGrupo;
      produtoCarrinho.Nome = produto.Nome;
      produtoCarrinho.PossuiDesconto = produto.PossuiDesconto;
      produtoCarrinho.Descricao = produto.Descricao;
      produtoCarrinho.ValorNutricional = produto.ValorNutricional;
      produtoCarrinho.Avisos = produto.Avisos;
      produtoCarrinho.PathImagem = produto.PathImagem;
      produtoCarrinho.Preco = produto.Preco;
      produtoCarrinho.RelacaoGrupoAcompanhamento = produto.RelacaoGrupoAcompanhamento;

      produtoCarrinho.ObservacaoNoPedido = observacao;
      produtoCarrinho.Quantidade = quantidade;
      produtoCarrinho.ValorTotal = Math.round((valorTotal) * 100.00) / 100.00;

      produtoCarrinho.RelacaoAcompanhamentoCarrinho = this.CarregarAcompanhamentosProduto(produtoCarrinho.Codigo);

      if (relacaoAcompanhamento) {
        relacaoAcompanhamento.forEach(g => {

          let indexGrupo: number = produtoCarrinho.RelacaoAcompanhamentoCarrinho.findIndex(x => x.CodigoGrupoAcompanhamento == g.CodigoGrupoAcompanhamento);

          g.RelacaoAcompanhamento.forEach(a => {
            let indexAcomp = produtoCarrinho.RelacaoAcompanhamentoCarrinho[indexGrupo].RelacaoAcompanhamento.findIndex(x => x.Codigo == a.Codigo);
            produtoCarrinho.RelacaoAcompanhamentoCarrinho[indexGrupo].RelacaoAcompanhamento[indexAcomp].Selecionado = a.Selecionado;
            produtoCarrinho.RelacaoAcompanhamentoCarrinho[indexGrupo].RelacaoAcompanhamento[indexAcomp].QuantidadeSelecionada = a.QuantidadeSelecionada;
            produtoCarrinho.RelacaoAcompanhamentoCarrinho[indexGrupo].RelacaoAcompanhamento[indexAcomp].Posicao1 = a.Posicao1;
            produtoCarrinho.RelacaoAcompanhamentoCarrinho[indexGrupo].RelacaoAcompanhamento[indexAcomp].Posicao2 = a.Posicao2;
            produtoCarrinho.RelacaoAcompanhamentoCarrinho[indexGrupo].RelacaoAcompanhamento[indexAcomp].Posicao3 = a.Posicao3;
            produtoCarrinho.RelacaoAcompanhamentoCarrinho[indexGrupo].RelacaoAcompanhamento[indexAcomp].Posicao4 = a.Posicao4;
            produtoCarrinho.RelacaoAcompanhamentoCarrinho[indexGrupo].RelacaoAcompanhamento[indexAcomp].Pizza = a.Pizza;
            produtoCarrinho.RelacaoAcompanhamentoCarrinho[indexGrupo].RelacaoAcompanhamento[indexAcomp].Sabores = a.Sabores;

            if (a.Selecionado) {
              //produtoCarrinho.ValorTotal += Math.round(((a.ValorAcompanhamento * a.QuantidadeSelecionada) * produtoCarrinho.Quantidade) * 100.00) / 100.00;
            }
          });
        });
      }

      this._produto.RelacaoCarrinho.push(produtoCarrinho);

      this.onGravarConteudoStorageProduto();

      // Adiciono o produto no carrinho 
      Global.QuantidadeItensCarrinho += produtoCarrinho.Quantidade;

      produto.MensagemAdicionado = true;
      setTimeout(() => {
        produto.MensagemAdicionado = false;
      }, 1000);

      //this.alertaSrv.MensagemPopUp("Adicionado ao Carrinho...");
    }
  }

  private async onGravarConteudoStorageProduto(): Promise<void> {
    let c = async () => {
      return new Promise<void>(async (resolve) => {
        this._produto.RelacaoCarrinho.forEach(e => {
          e.PathImagem = '';
        })

        Global.Produto = this._produto;
        // let json = JSON.stringify(this._produto);
        // this.GravarConteudoStorage(Global.ChaveProduto, json);

        resolve();
      });
    };

    let ret: string = '';
    await c().then(res => { })
      .catch(e => console.log(e));
  }

  private async onCarregarConteudoStorageProduto(): Promise<void> {
    let c = async () => {
      return new Promise<void>(async (resolve) => {
        //this._produto = Global.Produto

        if (this._produto) {
          this._produto.RelacaoCarrinho.forEach(e => {
            let index: number = this._produto.Relacao.findIndex(x => x.Codigo == e.Codigo);
            e.PathImagem = this._produto.Relacao[index].PathImagem;
          });
        }

        resolve();
      });
    };

    let ret: string = '';
    await c().then(res => { })
      .catch(e => console.log(e));
  }

  async CarregarFotosProdutos() {
    let c = async () => {
      return new Promise<void>(async (resolve) => {
        //Mantenho a lista carregada, para o caso de não sincronizado
        let produto: ProdutoModel = await this.httpSrv.CarregarInformacoesImagensProdutos(Global.PaginaAtualPesquisaImagemProduto);
        if ((produto.Sucesso) &&
          (produto.Relacao)) {
          produto.Relacao.forEach(e => {
            this._imagensProduto.push(e);
          })
        }

        if ((this._imagensProduto) &&
          (this._produto) &&
          (this._produto.Relacao)) {
          this._produto.Relacao.forEach(e => {
            let index: number = this._imagensProduto.findIndex(x => x.Codigo == e.Codigo);
            if (index > -1)
              e.PathImagem = this._imagensProduto[index].PathImagem;
          })

          Global.PaginaAtualPesquisaImagemProduto++;

          await this.onGravarConteudoStorageProduto();
        }

        if((produto) &&
        (!produto.Relacao))
        {this._produto.Relacao.forEach(e => {
          if (e.PathImagem == "../../../assets/carregando.gif")
            e.PathImagem = "../../../assets/noImage.png"
        })
        
        }

        resolve();
      });
    };

    await c()
      .then(res => {
      })
      .catch(e => {
        console.log(e);
      });
  }

  AtualizarProdutoAoCarrinho(produto: DetalheProdutoModel) {
    if (produto.Quantidade == 0) {
      this.alertaSrv.Alerta("Quantidade", "Informe uma quantidade válida deste produto", true);
      return;
    }

    let index: number = this._produto.RelacaoCarrinho.findIndex(y => y.SequencialCarrinho == produto.SequencialCarrinho);
    this._produto.RelacaoCarrinho[index] = produto;

    this.onGravarConteudoStorageProduto();

    this.alertaSrv.MensagemPopUp("Produto Atualizado no Carrinho...");
  }

  CarregarAcompanhamentosProduto(codigoProduto: number): DetalheProdutoGrupoAcompanhamentoModel[] {
    let retorno: DetalheProdutoGrupoAcompanhamentoModel[] = []

    if ((this._produto.Relacao) &&
      (this._acompanhamento) &&
      (this._acompanhamento.Relacao)) {
      let index = this._produto.Relacao.findIndex(y => y.Codigo == codigoProduto);
      let produto = this._produto.Relacao[index];

      if (produto.RelacaoGrupoAcompanhamento) {
        produto.RelacaoGrupoAcompanhamento.forEach(c => {
          let acompanhamentoCarrinho = new DetalheProdutoGrupoAcompanhamentoModel();
          acompanhamentoCarrinho.CodigoGrupoAcompanhamento = c.CodigoGrupoAcompanhamento;
          acompanhamentoCarrinho.RelacaoAcompanhamento = [];

          this._acompanhamento.Relacao.forEach(y => {
            if (acompanhamentoCarrinho.CodigoGrupoAcompanhamento == y.CodigoGrupoAcompanhamento) {
              acompanhamentoCarrinho.QuantidadeMaximaGrupo = y.QuantidadeMaximaGrupo;
              acompanhamentoCarrinho.NomeGrupo = y.NomeGrupoAcompanhamento;
              acompanhamentoCarrinho.ID = acompanhamentoCarrinho.NomeGrupo.split(' ').join('_');
              acompanhamentoCarrinho.Link = "#" + acompanhamentoCarrinho.NomeGrupo.split(' ').join('_');
              acompanhamentoCarrinho.Obrigatorio = y.Obrigatorio;
              acompanhamentoCarrinho.MaiorValor = y.MaiorValor;
              acompanhamentoCarrinho.Pizza = y.Pizza;
              //acompanhamentoCarrinho.QuantidadeSelecionadaGrupo = 0;

              let acompanhamentoProduto: DetalheProdutoAcompanhamentoModel = new DetalheProdutoAcompanhamentoModel();
              acompanhamentoProduto.CodigoGrupoAcompanhamento = acompanhamentoCarrinho.CodigoGrupoAcompanhamento;
              acompanhamentoProduto.NomeGrupoAcompanhamento = acompanhamentoCarrinho.NomeGrupo;
              acompanhamentoProduto.Codigo = y.Codigo;
              acompanhamentoProduto.Acompanhamento = y.Acompanhamento;
              acompanhamentoProduto.QuantidadeMaximaAcompanhamento = y.QuantidadeMaximaAcompanhamento;
              acompanhamentoProduto.ValorAcompanhamento = y.ValorAcompanhamento;
              if (y.RelacaoProdutoAcompanhamento) {
                y.RelacaoProdutoAcompanhamento.forEach(x => {
                  if (x.CodigoProduto == codigoProduto) {
                    acompanhamentoProduto.CodigoProdutoAcompanhamento = x.CodigoProdutoAcompanhamento;
                    acompanhamentoProduto.QuantidadeProdutoAcompanhamento = x.QuantidadeProdutoAcompanhamento;
                  }
                });
              }

              if (acompanhamentoProduto.Selecionado == undefined) {
                acompanhamentoProduto.Selecionado = false;
              }
              acompanhamentoProduto.QuantidadeSelecionada = 0;

              acompanhamentoCarrinho.RelacaoAcompanhamento.push(acompanhamentoProduto);
            }
          });

          if (acompanhamentoCarrinho.RelacaoAcompanhamento.length > 0) {
            retorno.push(acompanhamentoCarrinho);
          }
        });
      }
    }

    return retorno;
  }

  async SelecionarProdutosComprados(): Promise<DetalheProdutoModel[]> {
    let relacao: DetalheProdutoModel[] = [];
    Global.QuantidadeItensCarrinho = 0;

    let c = async () => {
      return new Promise(async (resolve) => {
        if ((this._produto) &&
          (this._produto.RelacaoCarrinho)) {
          this._produto.RelacaoCarrinho.forEach(e => {
            // if (!e.RelacaoAcompanhamentoCarrinho) {
            //   e.RelacaoAcompanhamentoCarrinho = [];

            //   if (e.RelacaoGrupoAcompanhamento) {
            //     e.RelacaoGrupoAcompanhamento.forEach(c => {
            //       let acompanhamentoCarrinho = new DetalheProdutoGrupoAcompanhamentoModel();
            //       acompanhamentoCarrinho.CodigoGrupoAcompanhamento = c.CodigoGrupoAcompanhamento;

            //       acompanhamentoCarrinho.RelacaoAcompanhamento = [];

            //       this._acompanhamento.Relacao.forEach(y => {
            //         if (acompanhamentoCarrinho.CodigoGrupoAcompanhamento == y.CodigoGrupoAcompanhamento) {
            //           acompanhamentoCarrinho.QuantidadeMaximaGrupo = y.QuantidadeMaximaGrupo;
            //           acompanhamentoCarrinho.NomeGrupo = y.NomeGrupoAcompanhamento;
            //           acompanhamentoCarrinho.Obrigatorio = y.Obrigatorio;
            //           acompanhamentoCarrinho.QuantidadeSelecionadaGrupo = 0;

            //           let acompanhamentoProduto: DetalheProdutoAcompanhamentoModel = new DetalheProdutoAcompanhamentoModel();
            //           acompanhamentoProduto.CodigoGrupoAcompanhamento = acompanhamentoCarrinho.CodigoGrupoAcompanhamento;
            //           acompanhamentoProduto.NomeGrupoAcompanhamento = acompanhamentoCarrinho.NomeGrupo;
            //           acompanhamentoProduto.Codigo = y.Codigo;
            //           acompanhamentoProduto.Acompanhamento = y.Acompanhamento;
            //           acompanhamentoProduto.QuantidadeMaximaAcompanhamento = y.QuantidadeMaximaAcompanhamento;
            //           acompanhamentoProduto.ValorAcompanhamento = y.ValorAcompanhamento;
            //           acompanhamentoProduto.QuantidadeSelecionada = 0;

            //           acompanhamentoCarrinho.RelacaoAcompanhamento.push(acompanhamentoProduto);
            //         }
            //       });

            //       if (acompanhamentoCarrinho.RelacaoAcompanhamento.length > 0) {
            //         e.RelacaoAcompanhamentoCarrinho.push(acompanhamentoCarrinho);
            //       }
            //     });

            //     e.TemAcompanhamento = e.RelacaoAcompanhamentoCarrinho.length > 0;
            //   }
            // }

            let index: number = this._produto.Relacao.findIndex(x => x.Codigo == e.Codigo);
            e.PathImagem = this._produto.Relacao[index].PathImagem;
            Global.QuantidadeItensCarrinho += e.Quantidade;
            relacao.push(e);
          });
        }

        resolve(relacao);
      });
    };

    await c().then(res => { }).catch(e => { console.log(e); });
    return relacao;
  }

  async CarregarFormasPagamento(): Promise<DetalheFormaPagamentoModel[]> {
    let relacao: DetalheFormaPagamentoModel[] = [];

    let c = async () => {
      return new Promise(async (resolve) => {
        if (this._formaPagamento.Relacao) {
          relacao = this._formaPagamento.Relacao;
        }

        resolve(relacao);
      });
    };

    await c().then(res => { }).catch(e => { console.log(e); });
    return relacao;
  }

  async SincronizarCarrinhoCompra(carrinho: CarrinhoModel,
    relacaoProdutos: DetalheProdutoModel[],
    relacaoAcompanhamento: DetalheAcompanhamentoModel[]): Promise<RetornoModel> {
    let c = async () => {
      return new Promise(async (resolve) => {

        let r: RetornoModel = await this.httpSrv.SincronizarCarrinhoCompra(this._usuario, carrinho, relacaoProdutos, relacaoAcompanhamento);

        resolve(r);
      });
    };

    let ret: RetornoModel = new RetornoModel();
    await c().then(res => { ret = (res as RetornoModel); })
      .catch(e => {
        ret.Erro = true;
        ret.Mensagem = String(e) + String(e.error);
        console.log(e);
      });
    return ret;
  }

  async AtualizarRelacaoPedidos(): Promise<PedidoModel> {
    let c = async () => {
      return new Promise(async (resolve) => {

        if (this._usuario) {
          let r: PedidoModel = await this.httpSrv.AtualizarRelacaoPedidos(this._usuario.Codigo);
          resolve(r);
        }
        else {
          let r: PedidoModel = new PedidoModel();
          r.Sucesso = false;
          r.Mensagem = "Nenhum usuário logado na aplicação"

          resolve(r);
        }
      });
    };

    let ret: PedidoModel = new PedidoModel();
    await c().then(res => { ret = (res as PedidoModel); })
      .catch(e => {
        ret.Sucesso = false;
        ret.Mensagem = String(e);
        console.log(e);
      });
    return ret;
  }

  async ReiniciarOperacao() {
    Global.PaginaAtualPesquisaProduto = 1;
    Global.CarregamentoCompleto = false;
    Global.PaginaAtualPesquisaImagemProduto = 1;
    Global.CarregamentoCompletoImagem = false;
    await this.CarregarinformacoesFuncionamentoSistema();
  }

  async AtualizarProdutoCarrinhoCompra(sequencial: number, quantidade: number): Promise<void> {
    let c = async () => {
      return new Promise<void>(async (resolve) => {
        let index = -1;
        for (let i = 0; i < this._produto.RelacaoCarrinho.length; i++) {
          let x = this._produto.RelacaoCarrinho.findIndex(y => y.SequencialCarrinho === sequencial);

          if (x > -1) {
            index = x;
            break;
          }
        }

        if (index > -1) {
          this._produto.RelacaoCarrinho[index].Quantidade = quantidade;
          this._produto.RelacaoCarrinho[index].ValorTotal = Math.round((this._produto.RelacaoCarrinho[index].Quantidade * this._produto.RelacaoCarrinho[index].Preco) * 100.00) / 100.00;

          if (this._produto.RelacaoCarrinho[index].RelacaoAcompanhamentoCarrinho) {
            this._produto.RelacaoCarrinho[index].RelacaoAcompanhamentoCarrinho.forEach(g => {
              g.RelacaoAcompanhamento.forEach(a => {
                if (a.Selecionado) {
                  this._produto.RelacaoCarrinho[index].ValorTotal += Math.round(((a.ValorAcompanhamento * a.QuantidadeSelecionada) * this._produto.RelacaoCarrinho[index].Quantidade) * 100.00) / 100.00;
                }
              });
            });
          }

          this.onGravarConteudoStorageProduto();
        }
        resolve();
      });
    };

    await c().then(res => { }).catch(e => { console.log(e); });
  }

  async AtualizarObservacaoProdutoCarrinhoCompra(sequencial: number, observacao: string): Promise<void> {
    let c = async () => {
      return new Promise<void>(async (resolve) => {
        let index = -1;
        for (let i = 0; i < this._produto.RelacaoCarrinho.length; i++) {
          let x = this._produto.RelacaoCarrinho.findIndex(y => y.SequencialCarrinho === sequencial);

          if (x > -1) {
            index = x;
            break;
          }
        }

        if (index > -1) {
          this._produto.RelacaoCarrinho[index].ObservacaoNoPedido = observacao;

          this.onGravarConteudoStorageProduto();
        }
        resolve();
      });
    };

    await c().then(res => { }).catch(e => { console.log(e); });
  }

  async RemoverProdutoCarrinhoCompra(sequencial: number): Promise<void> {
    let c = async () => {
      return new Promise<void>(async (resolve) => {
        let index = -1;
        for (let i = 0; i < this._produto.RelacaoCarrinho.length; i++) {
          let x = this._produto.RelacaoCarrinho.findIndex(y => y.SequencialCarrinho === sequencial);

          if (x > -1) {
            index = x;
            break;
          }
        }

        if (index > -1) {
          Global.QuantidadeItensCarrinho -= this._produto.Relacao[index].Quantidade;
          this._produto.RelacaoCarrinho.splice(index, 1);
        }

        this.onGravarConteudoStorageProduto();

        resolve();
      });
    };

    await c().then(res => { }).catch(e => { console.log(e); });
  }

  async CarregarInformacoesFormaPagamento(): Promise<void> {
    let c = async () => {
      return new Promise<void>(async (resolve) => {
        this._formaPagamento = await this.httpSrv.CarregarInformacoesFormaPagamento();

        if (this._formaPagamento) {
          Global.FormaPagamento = this._formaPagamento;
          // let e = JSON.stringify(this._formaPagamento);
          // this.GravarConteudoStorage(Global.ChaveFormaPagamento, e);
        }

        resolve();
      });
    };

    if (!Global.EhCardapioVirtual) {
      await c().then(res => { }).catch(e => { console.log(e); });
    }
  }

  // async ObterQuantidadeProdutosCarrinho(): Promise<number> {
  //   let c = async () => {
  //     return new Promise(async (resolve) => {
  //       let quantidade: number = 0;
  //       if ((this._produto) && (this._produto.RelacaoCarrinho)) {
  //         quantidade = this._produto.RelacaoCarrinho.length;
  //       }

  //       resolve(quantidade);
  //     });
  //   };

  //   let ret: number = 0;
  //   await c().then(res => { ret = (res as number); }).catch(e => { console.log(e); });

  //   return ret;
  // }

  // public GravarInformacoesLocalEntrega() {
  //   if (this._localEntrega) {
  //     let l = JSON.stringify(this._localEntrega);
  //     this.GravarConteudoStorage(Global.ChaveLocalEntrega, l);

  //     Global.LocalEntrega = this._localEntrega;
  //   }
  // }

  public GravarinformacoesObservacaoPedido(observacao: string) {
    Global.ObservacaoPedido = observacao;
    //this.GravarConteudoStorage(Global.ChaveObservacaoPedido, observacao);
  }

  async ValidarDisponibilidadeEntregaNoCep(cep: string, validaFaixa: boolean, alteraEndereco: boolean): Promise<RetornoModel> {
    let retorno: RetornoModel = new RetornoModel();
    retorno.Mensagem = "Não foi possível validar o CEP de entrega";
    let c = async () => {
      return new Promise(async (resolve) => {
        retorno = await this.httpSrv.ValidarDisponibilidadeEntregaNoCep(cep, validaFaixa);

        if (retorno.Sucesso) {
          if (alteraEndereco) {
            Global.LocalEntrega.LocalEntrega = enLocalEntrega.Casa;
            Global.LocalEntrega.Cep = cep;
            Global.LocalEntrega.Endereco = retorno.Objeto.Endereco;
            Global.LocalEntrega.Bairro = retorno.Objeto.Bairro;
            Global.LocalEntrega.Cidade = retorno.Objeto.Cidade;
          }
          Global.LocalEntrega.CepLocalizado = retorno.Objeto.Localizado;
          Global.LocalEntrega.TaxaEntrega = retorno.Objeto.TaxaEntrega;
        }

        resolve(retorno);
      });
    };

    await c().then(res => { retorno = (res as RetornoModel); }).catch(e => { console.log(e); });
    return retorno;
  }

  async ValidarDisponibilidadeEntregaLatLng(latitude: any, longitude: any): Promise<RetornoModel> {
    let retorno: RetornoModel = new RetornoModel();
    retorno.Mensagem = "Não foi possível validar o Endereço de entrega";

    let c = async () => {
      return new Promise(async (resolve) => {
        retorno = await this.httpSrv.ValidarDisponibilidadeEntregaLatLng(latitude, longitude);

        if (retorno.Sucesso) {
          Global.LocalEntrega.TaxaEntrega = retorno.Objeto.TaxaEntrega;
        }

        resolve(retorno);
      });
    };

    await c().then(res => { retorno = (res as RetornoModel); }).catch(e => { console.log(e); });
    return retorno;
  }

  async CarregarEnderecoPeloCep(cep: string): Promise<LocalEntregaModel> {
    let retorno: LocalEntregaModel = new LocalEntregaModel();
    retorno.Mensagem = "Não foi possível validar o CEP de entrega";

    let c = async () => {
      return new Promise(async (resolve) => {
        let r: RetornoModel = await this.httpSrv.ValidarDisponibilidadeEntregaNoCep(cep, false);

        retorno.Sucesso = r.Sucesso;
        retorno.Mensagem = r.Mensagem;

        //if (r.Sucesso) {
        retorno.Cep = r.Objeto.Cep;
        retorno.Endereco = r.Objeto.Endereco;
        retorno.Bairro = r.Objeto.Bairro;
        retorno.Cidade = r.Objeto.Cidade;
        retorno.ListaTaxaEntrega = r.Objeto.ListaTaxaEntrega;
        //}

        resolve(retorno);
      });
    };

    await c().then(res => { retorno = (res as LocalEntregaModel); }).catch(e => { console.log(e); });
    return retorno;
  }

  async CarregarBairrosEntrega(): Promise<LocalEntregaModel> {
    let retorno: LocalEntregaModel = new LocalEntregaModel();
    retorno.Mensagem = "Não foi possível carregar os Bairros de Entrega";

    let c = async () => {
      return new Promise(async (resolve) => {
        let r: RetornoModel = await this.httpSrv.CarregarBairrosEntregaEmpresa();

        retorno.Sucesso = r.Sucesso;
        retorno.Mensagem = r.Mensagem;
        if (r.Objeto)
          retorno.ListaTaxaEntrega = r.Objeto.ListaTaxaEntrega;

        resolve(retorno);
      });
    };

    await c().then(res => { retorno = (res as LocalEntregaModel); }).catch(e => { console.log(e); });
    return retorno;
  }

  async CarregarAcompanhamentoPedido(): Promise<DetalheAcompanhamentoModel[]> {
    let relacao: DetalheAcompanhamentoModel[] = [];

    let c = async () => {
      return new Promise(async (resolve) => {
        if (this._acompanhamento.Relacao) {
          this._acompanhamento.Relacao.forEach(e => {
            if (e.TipoAcompanhamento == enTipoAcompanhamento.Pedido) {
              e.ValorAcompanhamento = 0.00;
              relacao.push(e);
            }
          })
        }

        resolve(relacao);
      });
    };

    await c().then(res => { }).catch(e => { console.log(e); });
    return relacao;
  }

  async AtualizarAcompanhamentoCarrinhoCompra(codigo: number, quantidade: number): Promise<void> {
    let c = async () => {
      return new Promise<void>(async (resolve) => {
        let index = -1;
        for (let i = 0; i < this._acompanhamento.Relacao.length; i++) {
          let x = this._acompanhamento.Relacao.findIndex(y => y.Codigo === codigo);

          if (x > -1) {
            index = x;
            break;
          }
        }

        if (index > -1) {
          this._acompanhamento.Relacao[index].QuantidadeSelecionada = quantidade;

          Global.Acompanhamento = this._acompanhamento;
          // let json = JSON.stringify(this._acompanhamento);
          // this.GravarConteudoStorage(Global.ChaveAcompanhamento, json);
        }
        resolve();
      });
    };

    await c()
      .then(res => { })
      .catch(e => { console.log(e); });
  }

  async IdentificarEmpresa(): Promise<IdentificacaoModel> {
    let retorno: IdentificacaoModel = new IdentificacaoModel();

    let c = async () => {
      return new Promise(async (resolve) => {
        let r: IdentificacaoModel = await this.httpSrv.IdentificarEmpresa();
        resolve(r);
      });
    };

    await c()
      .then(res => {
        retorno = (res as IdentificacaoModel);
        if (!retorno.EmpresaAtiva) {
          retorno.Mensagem = "Empresa está desativada no sistema...";
        }
      })
      .catch(e => {
        this.alertaSrv.Alerta("Erro", "Erro localizando a ID desta empresa", false);
        console.log(e)
      })
      .finally(() => {
        //this.spinnerSrv.Hide();
      });

    return retorno;
  }

  async AlterarSenha(login: string,
    senha: string,
    novaSenha: string,
    novaSenhaConfirmacao: string): Promise<RetornoModel> {
    let c = async () => {
      return new Promise(async (resolve) => {

        let usuario: UsuarioModel = await this.RealizarLoginUsuario(login, senha, '');
        let retorno: RetornoModel = new RetornoModel();

        let validacao: string = (!login) ? "Usuário não logado.<br/>" : "";
        validacao += (!(usuario.Sucesso && usuario.Autenticado)) ? "Senha atual informada não é válida.<br/>" : "";
        validacao += (!senha) ? "Senha não informada.<br/>" : "";
        validacao += (!novaSenha) ? "Nova Senha não informada.<br/>" : "";
        validacao += (!novaSenhaConfirmacao) ? "Nova senha confirmação não informada.<br/>" : "";
        validacao += (novaSenha != novaSenhaConfirmacao) ? "Novas senhas não conferem.<br/>" : "";

        if (validacao) {
          retorno.Sucesso = false;
          retorno.Mensagem = validacao;
        }
        else {
          retorno = await this.httpSrv.AlterarSenha(login, novaSenha);

          if (!Global.EhProducao) {
            console.log(retorno);
          }
        }

        resolve(retorno);
      });
    };

    let ret: RetornoModel = new RetornoModel();
    await c().then(res => { ret = (res as RetornoModel); }).catch(e => console.log(e));
    return ret;
  }

  async AlterarEndereco(login: string,
    cep: string,
    endereco: string,
    bairro: string,
    cidade: string,
    numeroEndereco: string,
    complemento: string,
    telefone: string,
    validaCEP: boolean): Promise<RetornoModel> {
    let c = async () => {
      return new Promise(async (resolve) => {

        let retorno: RetornoModel = new RetornoModel();

        let validacao: string = (!login) ? "Login não informado.<br/>" : "";
        if (validaCEP)
          validacao += (!cep) ? "CEP  não informado.<br/>" : "";
        validacao += (!numeroEndereco) ? "Número do Endereço de Entrega não informado.<br/>" : "";
        if (!telefone) {
          validacao += "Número de telefone não informado.<br/>";
        }
        else if (telefone.length < 14) {
          validacao += (!telefone) ? "Número de telefone inválido.<br/>" : "";
        }

        if (validacao) {
          retorno.Sucesso = false;
          retorno.Mensagem = validacao;
        }
        else {
          retorno = await this.httpSrv.AlterarEndereco(login,
            cep,
            endereco,
            bairro,
            cidade,
            numeroEndereco,
            complemento,
            telefone);

          if (!Global.EhProducao) {
            console.log(retorno);
          }
        }

        resolve(retorno);
      });
    };

    let ret: RetornoModel = new RetornoModel();
    await c().then(res => { ret = (res as RetornoModel); }).catch(e => console.log(e));
    return ret;
  }

  async AtualizarAcompanhamentoProdutoCarrinhoCompra(produto: DetalheProdutoModel, acompanhamentoProduto: DetalheAcompanhamentoModel): Promise<void> {
    let c = async () => {
      return new Promise<void>(async (resolve) => {
        // let index: number = this._produto.RelacaoCarrinho.findIndex(x => x.Codigo == produto.Codigo);
        // if ((index > -1) &&
        //   (this._produto.RelacaoCarrinho[index].RelacaoAcompanhamentoCarrinho)) {

        //   let indexGrupo: number = this._produto.RelacaoCarrinho[index].RelacaoAcompanhamentoCarrinho.findIndex(x => x.CodigoGrupoAcompanhamento == acompanhamentoProduto.CodigoGrupoAcompanhamento);

        //   if ((indexGrupo > -1) &&
        //     (this._produto.RelacaoCarrinho[index].RelacaoAcompanhamentoCarrinho[indexGrupo].RelacaoAcompanhamento)) {

        //     // Zero a quantidade do grupo de produtos
        //     //this._produto.RelacaoCarrinho[index].RelacaoAcompanhamentoCarrinho[indexGrupo].QuantidadeSelecionadaGrupo = 0;
        //     this._produto.RelacaoCarrinho[index].RelacaoAcompanhamentoCarrinho[indexGrupo].RelacaoAcompanhamento.forEach(y => {
        //       if (y.CodigoGrupoAcompanhamento == acompanhamentoProduto.CodigoGrupoAcompanhamento && y.Codigo == acompanhamentoProduto.Codigo) {
        //         y.Selecionado = acompanhamentoProduto.
        //         y.QuantidadeSelecionada = acompanhamentoProduto.QuantidadeSelecionada
        //       }

        //       // Se eu tiver acompanhamento, incremento 1 na quantidade de produtos selecionados no grupo
        //       if (y.QuantidadeSelecionada > 0) {
        //         this._produto.RelacaoCarrinho[index].RelacaoAcompanhamentoCarrinho[indexGrupo].QuantidadeSelecionadaGrupo++;
        //       }
        //     });
        //   }
        // }

        resolve();
      });
    };

    await c()
      .then(res => {
        this.onGravarConteudoStorageProduto();
      })
      .catch(e => { console.log(e); });
  }

  RealizarEnvioPedidosViaWhatsapp(carrinho: CarrinhoModel,
    localEntrega: LocalEntregaModel,
    codigoCarrinho: any,
    tempoAtendimento: any,
    valorTroco: number) {
    if ((carrinho) && (this._empresa.Whatsapp)) {
      let replicate: string = "-------------------------------\n";
      let valorTotal: number = 0.00;
      let url: string = "https://wa.me/" + this._empresa.Whatsapp + "?text=";
      let pedido: string = "Olá " + this._empresa.Nome + "!!!\n";
      pedido += "Foi realizado um pedido no seu app com os seguintes DADOS:\n";

      pedido += replicate;
      pedido += "*Código do PEDIDO*: " + codigoCarrinho + "\n";
      pedido += "*Atendimento*: " + tempoAtendimento + "\n";

      this.CarregarInformacoesUsuario();
      pedido += replicate;
      if (this._usuario) {
        pedido += "*Cliente*: " + this._usuario.Nome + "\n";
        pedido += "Email/Telefone: " + this._usuario.Login + "\n";
      }
      else {
        pedido += "*Cliente*: " + carrinho.Nome + "\n";
        pedido += "Email/Telefone: " + carrinho.Telefone + "\n";
      }

      pedido += replicate;
      pedido += "*Local*: " + (carrinho.LocalEntrega == enLocalEntrega.Loja
        ? "Retirar na Loja"
        : "Entrega em Casa") + "\n";

      pedido += replicate;
      if (carrinho.LocalEntrega == enLocalEntrega.Casa) {
        if (localEntrega.Cep)
          pedido += "*CEP*: " + localEntrega.Cep + "\n";
        pedido += "*Cidade*: " + localEntrega.Cidade + "\n";
        pedido += "*Bairro*: " + localEntrega.Bairro + "\n";
        pedido += "*End.*: " + localEntrega.Endereco + "\n";
        pedido += "*Núm.*: " + localEntrega.NumeroResidencia + "\n";
        if (localEntrega.Complemento) {
          pedido += "*Comp.*: " + localEntrega.Complemento + "\n";
        }
        pedido += replicate;
      }

      if (carrinho.CPF) {
        pedido += "*CPF NA NOTA*: " + (carrinho.CPF.length == 11 ? (carrinho.CPF.substring(0, 3) + "." + carrinho.CPF.substring(3, 6) + "." + carrinho.CPF.substring(6, 9) + "-" + carrinho.CPF.substring(9, 11)) : carrinho.CPF) + "\n";
        pedido += replicate;
      }

      pedido += "*PRODUTOS*\n";

      this._produto.RelacaoCarrinho.forEach(p => {
        valorTotal += p.ValorTotal;
        pedido += replicate;
        pedido += "- " + p.Nome + "\n";
        pedido += "Qtd: " + p.Quantidade + "\n";
        pedido += "Total: " + Global.FormatarMoeda(p.ValorTotal) + "\n";

        if (p.ObservacaoNoPedido) {
          pedido += "*Obs. deste Item*: " + p.ObservacaoNoPedido + "\n";
        }
      });

      pedido += replicate;

      if ((this._acompanhamento.Relacao) && (this._acompanhamento.Relacao.filter(rel => rel.QuantidadeSelecionada > 0).length > 0)) {
        pedido += "*ACOMPANHAMENTOS*\n";

        this._acompanhamento.Relacao.forEach(a => {
          if (a.QuantidadeSelecionada > 0) {
            pedido += replicate;
            pedido += "- " + a.Acompanhamento + "\n";
            pedido += "Qtd: " + a.QuantidadeSelecionada + "\n";
          }
        })
        pedido += replicate;
      }

      if (carrinho.Observacao) {
        pedido += "*Obs. do Pedido*: " + carrinho.Observacao + "\n";
        pedido += replicate;
      }

      pedido += "*Total Pedido*: " + Global.FormatarMoeda(valorTotal) + "\n";
      pedido += "*Taxa Entrega*: " + Global.FormatarMoeda(carrinho.TaxaEntrega) + "\n";
      if (carrinho.ValorDesconto) {
        pedido += "*Cupom*: - " + Global.FormatarMoeda(carrinho.ValorDesconto) + "\n";
        pedido += "*Total*: " + Global.FormatarMoeda(valorTotal + carrinho.TaxaEntrega - carrinho.ValorDesconto) + "\n";
      }
      else {
        pedido += "*Total*: " + Global.FormatarMoeda(valorTotal + carrinho.TaxaEntrega) + "\n";
      }
      pedido += "*Pagto*: " + carrinho.NomeFormaPagamento + "\n";

      if (valorTroco > 0.00) {
        pedido += "*Troco Para*: " + Global.FormatarMoeda(valorTroco) + "\n";
      }

      url += encodeURIComponent(pedido);

      if (!Global.EhProducao) {
        console.log(url);
      }

      const browser = this.browserSrv.create(url);
      browser.show();
    }
  }

  async CarregarInformacoesUsuario(): Promise<void> {
    let c = async () => {
      return new Promise(async (resolve) => {

        let usuario: UsuarioModel = Global.Usuario;
        // let usuario: UsuarioModel = null;
        // let conteudoUsuario = await this.CarregarConteudoStorage(Global.ChaveUsuario);
        // if (conteudoUsuario) {
        //   usuario = JSON.parse(conteudoUsuario);
        // }
        resolve(usuario);
      });
    };

    await c().then(res => { this._usuario = (res as UsuarioModel); }).catch(e => console.log(e));
  }

  async ReiniciarSenhaCliente(login: string): Promise<RetornoModel> {
    let c = async () => {
      return new Promise(async (resolve) => {

        let retorno: RetornoModel = await this.httpSrv.ReiniciarSenhaCliente(login);

        if (!Global.EhProducao) {
          console.log(retorno);
        }

        resolve(retorno);
      });
    };

    let ret: RetornoModel = new RetornoModel();
    await c().then(res => { ret = (res as RetornoModel); }).catch(e => console.log(e));
    return ret;
  }

  async EnviarSmsAutorizacao(login: string): Promise<RetornoModel> {
    let c = async () => {
      return new Promise(async (resolve) => {

        let retorno: RetornoModel = await this.httpSrv.EnviarSmsAutorizacao(login);

        if (!Global.EhProducao) {
          console.log(retorno);
        }

        resolve(retorno);
      });
    };

    let ret: RetornoModel = new RetornoModel();
    await c().then(res => { ret = (res as RetornoModel); }).catch(e => console.log(e));
    return ret;
  }

  async ObterCupomDescontoPeloNome(descricaoCupom: string, valorTotal: number): Promise<RetornoModel> {
    let retorno: RetornoModel = new RetornoModel();
    retorno.Mensagem = "Não foi possível validar o Cupom";
    let c = async () => {
      return new Promise(async (resolve) => {
        retorno = await this.httpSrv.ObterCupomDescontoPeloNome(descricaoCupom, valorTotal);
        resolve(retorno);
      });
    };

    await c().then(res => { retorno = (res as RetornoModel); }).catch(e => { console.log(e); });
    return retorno;
  }

  async ObterPedidoPeloCodigo(codigo: number): Promise<DetalhePedidoModel> {
    let detalhe: DetalhePedidoModel = new DetalhePedidoModel();

    let c = async () => {
      return new Promise(async (resolve) => {
        detalhe = await this.httpSrv.ObterPedidoPeloCodigo(codigo);

        resolve(detalhe);
      });
    };

    await c().then(res => { }).catch(e => { console.log(e); });
    return detalhe;
  }

  async RegistrarAcessoAplicacao(): Promise<RetornoModel> {
    let retorno: RetornoModel = new RetornoModel();

    let c = async () => {
      return new Promise(async (resolve) => {
        let r: RetornoModel = await this.httpSrv.RegistrarAcessoAplicacao();
        resolve(r);
      });
    };

    await c()
      .then(res => {
        retorno = (res as RetornoModel);
      })
      .catch(e => {
        console.log(e)
      })
      .finally(() => {
        //this.spinnerSrv.Hide();
      });

    return retorno;
  }
}
