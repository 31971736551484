import { Global } from '../helpers/global';
import { DetalheAcompanhamentoModel, DetalheProdutoAcompanhamentoModel } from './acompanhamentoModel';

export class ProdutoModel {
    constructor() {
        this.Sucesso = false;
    }

    public Sucesso: boolean;
    public Mensagem: string;

    public Relacao: DetalheProdutoModel[];
    public RelacaoCarrinho: DetalheProdutoModel[];
}

export class DetalheProdutoModel {
    public Codigo: number;
    public CodigoGrupo: number;
    public Nome: string;
    public PossuiDesconto: boolean;
    public Descricao: string;
    public ValorNutricional: string;
    public Avisos: string;
    public PathImagem: string;
    public Preco: number;
    public QuantidadeDisponivel: number;

    public ObservacaoNoPedido: string;
    public Quantidade: number;
    public ValorTotal: number;

    public RelacaoGrupoAcompanhamento: DetalheGrupoAcompanhamentoModel[];
    public RelacaoAcompanhamentoCarrinho: DetalheProdutoGrupoAcompanhamentoModel[];

    public TemAcompanhamento: boolean;
    public Busca: boolean;
    public TermosEncontrados: number;
    public SequencialCarrinho: number;
    public MensagemAdicionado: boolean;
}

export class DetalheGrupoAcompanhamentoModel {
    public CodigoGrupoAcompanhamento: number;
    // public NomeGrupo: string;
    // public QuantidadeMaxima: number;
}

export class DetalheProdutoGrupoAcompanhamentoModel {
    public CodigoGrupoAcompanhamento: number;
    public NomeGrupo: string;
    public Obrigatorio: boolean;
    public QuantidadeMaximaGrupo: number;
    public MaiorValor: boolean;
    public Pizza: boolean;
    public AlertaNaoSelecionado: boolean;
    public Link: string;
    public ID: string;
    //public QuantidadeSelecionadaGrupo: number;
    public RelacaoAcompanhamento: DetalheProdutoAcompanhamentoModel[];
    //public QuantidadeSelecionadaGrupo:any;
}
