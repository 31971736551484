import { Component, ChangeDetectorRef } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { DeliveryService } from './services/delivery/delivery.service';
import { AlertService } from './services/alert/alert.service';
import { Global } from './helpers/global';
import { NavigationStart, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {

  model: any = {};

  constructor(private router: Router,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private deliverySrv: DeliveryService,
    private alertaSrv: AlertService,
    private cdRef: ChangeDetectorRef
  ) {
    this.initializeApp();
  }

  async initializeApp() {
    await this.CarregarInformacoes();

    this.platform.ready().then(async () => {

      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  async CarregarInformacoes() {
    let reset = async () => {
      return new Promise<void>(async (resolve) => {
        this.router.navigate([Global.RotaAbertura]);
        this.deliverySrv.ResetarInformacaoCache();
        resolve();
      });
    };

    // capturo a URL. Ela será chamada no processo de identificação da empresa
    Global.UrlChamadora = this.platform.url();

    await reset()
      .then()
      .catch(e => console.log(e));

    //this.cdRef.detectChanges();
  }
}
