import { enLocalEntrega } from './enums/enLocalEntrega';

export class LocalEntregaModel {
    constructor() {
    }

    public Sucesso: boolean;
    public Mensagem: string;

    public CepLocalizado: boolean;
    public Cep: string;
    public CepValido: boolean;
    public Endereco: string;
    public Bairro: string;
    public Cidade: string;
    public NumeroResidencia: string;
    public Complemento: string;
    public TaxaEntrega: number;
    public ListaTaxaEntrega: BairrosEntrega[];
    public LocalEntrega: enLocalEntrega;
}

export class BairrosEntrega {
    public Bairro: string;
    public ValorTaxaEntrega: number;
    public Municipio: string;
}