import { DetalheProdutoModel } from './produtoModel';

export class GrupoModel {
    constructor() {
        this.Sucesso = false;
    }

    public Sucesso: boolean;
    public Mensagem: string;

    public Relacao: DetalheGrupoModel[];

    ObterRelacaoGrupoEmPares(): ParGrupoModel[] {
        let ret: ParGrupoModel[] = [];

        let posicao: number = 0;
        let passos: number = this.Relacao.length / 2;
        if ((this.Relacao.length % 2) > 0) {
            passos++;
        }

        for (let index = 0; index < passos; index++) {
            let par: ParGrupoModel = new ParGrupoModel();
            par.Grupo1 = this.Relacao[posicao];

            if ((posicao + 1 < this.Relacao.length)) {
                par.Grupo2 = this.Relacao[posicao + 1];
            }

            posicao += 2;

            ret.push(par);
        }
        return ret;
    }
}

export class DetalheGrupoModel {
    public Codigo: number;
    public Nome: string;
    public PossuiDesconto: boolean;
    public PathImagem: string;
    public PossuiProdutosCarregados: boolean;
    public CodigoNivel: number;
    public CodigoGrupoNivelSuperior: number;

    public RelacaoProdutos: DetalheProdutoModel[];

    public Ocultar: boolean;
    public ImagemOcultar: string = "../../../assets/up.png";
    public Busca: boolean;
    public Link: string;
    public ID: string;
    public Menu: string;
    public Ativo: boolean = false;
    public PossuiFilhos: boolean = false;
    public GrupoPai: boolean = false;
}

export class ParGrupoModel {
    public Grupo1: DetalheGrupoModel;
    public Grupo2: DetalheGrupoModel;
}
