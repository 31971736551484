import { CurrencyPipe, DatePipe } from '@angular/common';
import localePtBr from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
import { LocalEntregaModel } from '../models/localEntregaModel';
import { EmpresaModel } from '../models/empresaModel';
import { ProdutoModel, DetalheProdutoModel } from '../models/produtoModel';
import { FuncionamentoModel } from '../models/FuncionamentoModel';
import { GrupoModel } from '../models/grupoModel';
import { UsuarioModel } from '../models/usuarioModel';
import { FormaPagamentoModel } from '../models/FormaPagamentoModel';
import { AcompanhamentoModel } from '../models/acompanhamentoModel';
import { HorarioFuncionamentoModel } from '../models/horarioFuncionamentoModel';

export class Global {
    constructor() { }

    public static IdentificacaoEmpresa: string = '';
    public static EhCardapioVirtual: boolean = false;
    public static UrlChamadora: string = '';

    //Produção
    public static UrlServico: string = 'https://bitdelivery-api.azurewebsites.net/api/';

    //Servidor Local - HLG
    //public static UrlServico: string = 'http://192.168.1.114:4000/api/';

    //Servidor Local - DEV
    //public static UrlServico: string = 'http://192.168.1.114:4500/api/';
    
    //Máquina local
    //public static UrlServico: string = 'http://localhost:51747/api/';
    
    public static Versao: string = '1.0.6.2';
    public static Ambiente: string = 'P';
    public static AmbienteMocado: boolean = false;
    public static EhProducao: boolean = Global.Ambiente == 'P';

    /* Nomes de rotas */
    public static RotaTabs: string = 'tabs';
    public static RotaSplash: string = 'splash';
    public static RotaLogin: string = 'login';
    public static RotaValidaEntrega: string = 'validaEntrega';
    public static RotaCriarConta: string = 'criarConta';
    public static RotaDetalheGrupo: string = 'detalhe-grupo-page';
    public static RotaDetalheProduto: string = 'detalheProduto';
    public static RotaResumoCarrinho: string = 'resumoCarrinho';
    public static RotaCarrinho: string = 'tabs/Carrinho';
    public static RotaPerfil: string = 'perfil';
    public static RotaPedidos: string = 'pedidos'; 
    public static RotaAbertura: string = 'tabs/cardapio';
    public static RotaPaginaAbertura: string = 'cardapio'; 

    public static RotaMapa: string = 'mapa';
    public static AlteraSenha: string = 'alteraSenha';
    public static AlteraEndereco: string = 'alteraEndereco';
    public static HorarioFuncionamento: string = 'horarioFuncionamento';
    public static CardapioVirtual: string = 'cardapioVirtual';
    public static RotaLoginSms: string = 'loginSms'
    /* Chaves dos valores de Storage */
    // public static ChaveUsuario: string = 'Usuario';
    // public static ChaveEmpresa: string = 'Empresa';
    // public static ChaveGrupo: string = 'Grupos';
    // public static ChaveProduto: string = 'Produtos';
    // public static ChaveFormaPagamento: string = 'FormaPagamento';
    // public static ChaveFuncionamento: string = 'FuncionamentoEmpresa';
    // public static ChaveLocalEntrega: string = 'LocalEntrega';
    // public static ChaveObservacaoPedido: string = 'ObservacaoPedido';
    // public static ChaveAcompanhamento: string = 'AcompanhamentoPedido';    
    public static Usuario: UsuarioModel; //
    public static Empresa: EmpresaModel; //--
    public static GrupoProduto: GrupoModel; //--
    public static Produto: ProdutoModel; //--
    public static FormaPagamento: FormaPagamentoModel; //--
    public static Funcionamento: FuncionamentoModel; //--
    public static ObservacaoPedido: string; //--
    public static Acompanhamento: AcompanhamentoModel; //
    public static ListaHorarioFuncionamento: HorarioFuncionamentoModel;
    public static TempoSetFocus: number = 750;
    public static QuantidadeItensCarrinho: number = 0;
    public static PaginaAtualPesquisaProduto: number = 1;
    public static CarregamentoCompleto: boolean = false;
    public static PaginaAtualPesquisaImagemProduto: number = 1;
    public static CarregamentoCompletoImagem: boolean = false;

    // Tive que criar este camarada porque as informações de local de entrega do carrinho não estão sofrendo refresh
    public static LocalEntrega: LocalEntregaModel;

    // Objetos de memória
    // public static Empresa: EmpresaModel;
    // public static Funcionamento: FuncionamentoModel;
    // public static GrupoProduto: GrupoModel;
    // public static Produto: ProdutoModel;
    // public static ObjetoProduto: DetalheProdutoModel;
    // public static ObjetoRotaChamadora: string;
    // public static ObjetoOrigemCarrinho: boolean


    public static PadLeft(conteudo: string, quantidade: number, padding: string) {
        let n = conteudo.length;

        let join = '';
        if (quantidade > n) {
            for (let i = 0; i < (quantidade - n); i++) {
                join += padding;
            }
        }

        return join + conteudo;
    }

    public static async Sleep(tempo: number, acao: any) {
        let ex = (async () => {
            setTimeout(() => {
                acao();
            }, tempo);
        });

        await ex();
    }

    public static isNullOrEmpty(conteudo: any) {
        return (conteudo != null && conteudo != 'undefined' && conteudo.trim() != '');
    }

    public static FormatarMoeda(valor: number): string{
        registerLocaleData(localePtBr);
        let currencyPipe: CurrencyPipe = new CurrencyPipe("pt-BR");

        return currencyPipe.transform(valor, "BRL", "symbol", '0.2-2');
    }

    public static FormatarDataHora(data: Date): string{
        registerLocaleData(localePtBr);
        let datePipe: DatePipe = new DatePipe("pt-BR");

        return datePipe.transform(data, "dd/MM/yyyy HH:mm");
    }
}
