export class FormaPagamentoModel {
    constructor() {
        this.Sucesso = false;
    } 

    public Sucesso: boolean;
    public Mensagem: string;

    public Relacao: DetalheFormaPagamentoModel[];
}

export class DetalheFormaPagamentoModel {
    public Codigo: number;
    public Nome: string;
    public PermiteTroco: boolean;
    public Selecionado: boolean;
}