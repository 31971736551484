import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { Global } from './helpers/global';

const routes: Routes = [
  { path: '', redirectTo: Global.RotaSplash, pathMatch: 'full' },
  { path: Global.RotaSplash, loadChildren: () => import('./pages/splash-page/splash-page.module').then(m => m.SplashPagePageModule) },
  { path: Global.RotaTabs, loadChildren: () => import('./pages/tabs/tabs.module').then(m => m.TabsPageModule) },
  { path: Global.RotaLogin, loadChildren: () => import('./pages/login-page/login-page.module').then(m => m.LoginPagePageModule) },
  { path: Global.RotaPerfil, loadChildren: () => import('./pages/tabPerfil/perfil-page/perfil-page.module').then(m => m.PerfilPagePageModule) },
  { path: Global.RotaPedidos, loadChildren: () => import('./pages/tabPedidos/pedidos-cliente-page/pedidos-cliente-page.module').then(m => m.PedidosClientePagePageModule) },
  { path: Global.RotaCriarConta, loadChildren: () => import('./pages/criar-conta-page/criar-conta-page.module').then(m => m.CriarContaPagePageModule) },
  { path: Global.RotaDetalheGrupo, loadChildren: () => import('./pages/tabAbertura/detalhe-grupo-page/detalhe-grupo-page.module').then(m => m.DetalheGrupoPagePageModule) },
  { path: Global.RotaDetalheProduto, loadChildren: () => import('./pages/tabAbertura/detalhe-produto-page/detalhe-produto-page.module').then(m => m.DetalheProdutoPagePageModule) },
  { path: Global.RotaResumoCarrinho, loadChildren: () => import('./pages/tabCarrinho/resumo-carrinho-page/resumo-carrinho-page.module').then(m => m.ResumoCarrinhoPagePageModule) },
  { path: Global.RotaCarrinho, loadChildren: () => import('./pages/tabCarrinho/carrinho-page/carrinho-page.module').then(m => m.CarrinhoPagePageModule) },
  { path: Global.RotaValidaEntrega, loadChildren: () => import('./pages/tabCarrinho/valida-entrega/valida-entrega.module').then(m => m.ValidaEntregaPageModule) },
  { path: Global.RotaMapa, loadChildren: () => import('./pages/mapa-page/mapa-page.module').then( m => m.MapaPagePageModule) },
  { path: Global.AlteraSenha, loadChildren: () => import('./pages/tabPerfil/altera-senha-page/altera-senha-page.module').then( m => m.AlteraSenhaPagePageModule)},
  { path: Global.AlteraEndereco, loadChildren: () => import('./pages/tabPerfil/altera-endereco-page/altera-endereco-page.module').then( m => m.AlteraEnderecoPagePageModule)},
  { path: Global.HorarioFuncionamento, loadChildren: () => import('./pages/horario-fucionamento/horario-fucionamento.module').then( m => m.HorarioFucionamentoPageModule) },
  { path: Global.CardapioVirtual, loadChildren: () => import('./pages/tabAbertura/abertura-page/abertura-page.module').then( m => m.AberturaPagePageModule) },
  { path: Global.RotaLoginSms, loadChildren: () => import('./pages//login-sms/login-sms.module').then( m => m.LoginSmsPageModule) },
  { path: '**', redirectTo: Global.RotaSplash, pathMatch: 'full' },
  {
    path: 'login-sms',
    loadChildren: () => import('./pages/login-sms/login-sms.module').then( m => m.LoginSmsPageModule)
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
