import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { NetworkService } from './services/network/network.service';
import { HttpService } from './services/http/http.service';
import { AlertService } from './services/alert/alert.service';
import { SpinnerService } from './services/spinner/spinner.service';
import { InAppBrowser } from '../../node_modules/@ionic-native/in-app-browser/ngx';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
//import { MatTabsModule } from '@angular/material';
import { MatTabsModule } from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule,
    HttpClientModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    MatTabsModule,
    BrowserAnimationsModule],
  providers: [
    StatusBar,
    SplashScreen,
    SpinnerService,
    AlertService,
    HttpService,
    NetworkService,
    InAppBrowser,
    { provide: LocationStrategy, useClass: HashLocationStrategy }
    //{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
