export class FuncionamentoModel {
    constructor() {
        this.Sucesso = false;
    }

    public Sucesso: boolean;
    public Mensagem: string;

    public TempoMedioAtendimento: string;
    public HorarioAtendimento: string;
    public TaxaEntrega: number;
    public EmFuncionamento: boolean;
}
