export class PedidoModel {
    constructor() {
        this.Sucesso = false;
    } 

    public Sucesso: boolean;
    public Mensagem: string;

    public Relacao: DetalhePedidoModel[];   
}

export class DetalhePedidoModel{
    constructor() {
        this.Sucesso = false;
    } 

    public Sucesso: boolean;
    public Mensagem: string;
    
    public DataCriacao: Date;
    public ValorTotal: number;
    public Situacao: string;
    public Codigo: string;
    public MotivoCancelamento: string;
}