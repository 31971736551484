export class IdentificacaoModel {
    constructor() {
        this.Sucesso = false;
    }

    public Sucesso: boolean;
    public Mensagem: string;
    public Id: string;
    public EmpresaAtiva: boolean;
    public EhCardapioVirtual: boolean;
}