import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  private contador:number = 0;

  constructor(private loadingCtrl: LoadingController) {

  }

  async Show(mensagem: string, duracao:number=2000): Promise<void> {
    const loading = await this.loadingCtrl.create({
      message: (mensagem || 'Carregando...'),
      duration: duracao
    });

    await loading.present();
  }

  Hide() {
    if (this.loadingCtrl != null) {
      this.loadingCtrl.dismiss();
      //this.loadingCtrl = null;
    }
  }
}
