import { enTipoAcompanhamento } from './enums/enTipoAcompanhamento';

export class AcompanhamentoModel {
    public Sucesso: boolean;
    public Mensagem: string;

    public Relacao: DetalheAcompanhamentoModel[];
}

export class DetalheAcompanhamentoModel {
    public CodigoGrupoAcompanhamento: number;
    public NomeGrupoAcompanhamento: string;
    public Codigo: number;
    public Acompanhamento: string;
    public QuantidadeMaximaAcompanhamento: number;
    public ValorAcompanhamento: number;
    public QuantidadeMaximaGrupo: number;
    public Obrigatorio: boolean;
    public TipoAcompanhamento: enTipoAcompanhamento;
    public MaiorValor: boolean;
    public Pizza: boolean;
    public QuantidadeSelecionada: number;
    public CodigoProdutoAcompanhamento: number;
    public QuantidadeProdutoAcompanhamento: number;
    public Shake: boolean;

    public RelacaoProdutoAcompanhamento: AcompanhamentoPedidoProdutoModel[];
}

export class DetalheProdutoAcompanhamentoModel {
    public CodigoGrupoAcompanhamento: number;
    public NomeGrupoAcompanhamento: string;
    public Codigo: number;
    public Acompanhamento: string;
    public QuantidadeMaximaAcompanhamento: number;
    public ValorAcompanhamento: number;
    public Selecionado: boolean;
    public QuantidadeSelecionada: number;
    public CodigoProdutoAcompanhamento: number;
    public QuantidadeProdutoAcompanhamento: number;
    public Shake: boolean;
    public Posicao1: boolean;
    public Posicao2: boolean;
    public Posicao3: boolean;
    public Posicao4: boolean;
    public Pizza: boolean;
    public Sabores: number;
}

export class AcompanhamentoPedidoProdutoModel {
    public CodigoProduto: number;
    public CodigoGrupoAcompanhamento: number;
    public Codigo: number;
    public CodigoProdutoAcompanhamento: number;
    public QuantidadeProdutoAcompanhamento: number;
}
